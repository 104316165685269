import { createTheme } from '@mui/material/styles';

//默认主题: https://material-ui.com/zh/customization/default-theme/

const theme = createTheme({
    palette: {
        primary: {
            main: '#16b741',
        },
        secondary: {
            main: '#ea4335',
        },
        normal:{
            main:"#666",
            contrastText: '#fff',
        },
        // error: {
        //     main: '#b0e0bd',
        // },
        text: {
            primary: "#333",
        },
        // background: {
        //     default: '#101010',
        // },
    },
    typography:{
        fontFamily:'Helvetica, "Microsoft YaHei", "微软雅黑", Heiti, "黑体", sans-serif',
    },
    overrides:{
        MuiCssBaseline: {
            '@global': {
                body: {
                    backgroundColor:"#fff"
                },
            },
        },
        MuiListItemIcon:{
            root:{
                minWidth:30
            }
        },
        MuiCardHeader:{
            title:{
                fontSize:14,
                fontWeight:600
            },
            subheader:{
                fontSize:12
            }
        },
        MuiAppBar:{
            colorPrimary:{
                backgroundColor:"#fff"
            }
        },
        MuiButton:{
            containedPrimary:{
                color:"#fff"
            }
        },
        MuiDrawer:{
            paperAnchorDockedLeft:{
                borderRight:0
            }
        }
    }
});

export default theme;