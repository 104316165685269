import * as React from 'react'
import Helmet from 'react-helmet'
import {StaticQuery, graphql} from 'gatsby'
import {Header} from './Header'
import {Sidebar} from './sidebar'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { useI18next } from 'gatsby-plugin-react-i18next'
import theme from './theme'


export function RootLayout({children,type,pathname}) {
    return (
        <StaticQuery
            query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
            render={(data) => {
                const {title} = data.site.siteMetadata
                // const {t} = useI18next();

                return (
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={theme}>
                            <>
                                <Helmet
                                    title={data.site.siteMetadata.title}
                                    meta={[
                                        {name: 'description', content: "description"},
                                        {name: 'keywords', content: 'MT Photos, nas, photos, gallery,manager'},
                                    ]}
                                >
                                    <html lang="en"/>
                                </Helmet>
                                <Header siteTitle={title} pathname={pathname}/>

                                <div
                                    style={{
                                        display: 'grid',
                                        gridTemplateColumns: 'auto 1fr auto',
                                        height: '100%',
                                    }}
                                >
                                    {
                                        type === "full" ?
                                            null
                                            : <Sidebar pathname={pathname}/>
                                    }
                                    <div
                                        style={{
                                            background: '#fff',
                                            padding: 24,
                                            margin: 0,
                                        }}
                                    >
                                        {children}
                                    </div>
                                </div>
                            </>
                        </ThemeProvider>
                    </StyledEngineProvider>
                )
            }}
        />
    )
}

export default RootLayout
