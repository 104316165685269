import React from 'react'

import {AppBar, Box, Button, Drawer, IconButton, List, ListItem, ListItemText, Toolbar,} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import logo from './images/logo.png'
import {useTranslation, Link} from "gatsby-plugin-react-i18next";
import {getCurrentPathNav} from "./tools/path";


export function Header(props) {
    const {t} = useTranslation();
    const {siteTitle, pathname} = props

    const currentNav = getCurrentPathNav(pathname)

    const pages = [{
        text: t('nav_index'),
        link: "/",
        navTag:"/"
    }, {
        text: t('nav_start'),
        link: "/docs/get-started/introduction",
        navTag:"/doc"

    }, {
        text: t('nav_faq'),
        link: "/faq",
        navTag:"/faq"
    }, {
        text: t('nav_about'),
        link: "/about",
        navTag:"/about"
    }, {
        text: t('nav_contact'),
        link: "/contact",
        navTag:"/contact"
    }];

    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };


    const list = (anchor) => (
        <Box
            sx={{width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250}}
            role="presentation"
            onClick={handleCloseNavMenu}
            onKeyDown={handleCloseNavMenu}
        >
            <List>
                {pages.map(({text, link,navTag}, i) => (
                    <ListItem key={i} button className={currentNav === navTag ? "on" : ""}>
                        <ListItemText primary={text}/>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    return (
        <Box sx={{
            // overflow: "hidden",
            borderBottom: "1px solid #dadce0",
            backgroundColor: "#fff",
            height: {
                sm: 69,
                xs: 56
            }
        }}
        >
            <AppBar sx={{}} position="static" color="transparent" elevation={0}>
                <Toolbar>
                    <Link to="/">
                        <Box sx={{width: 120, height: 24,}}>
                            <img src={logo} alt=""/>
                        </Box>
                    </Link>
                    <Box sx={{flexGrow: 1, justifyContent: 'flex-end', display: {xs: 'flex', sm: 'none'}}}>
                        <IconButton
                            size="large"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon/>
                        </IconButton>

                        <Drawer
                            anchor="right"
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                        >
                            {list()}
                        </Drawer>
                    </Box>

                    <Box className="top-nav" component="ul"
                         sx={{flexGrow: 1, justifyContent: 'flex-end', display: {xs: 'none', sm: 'flex'}}}>
                        {pages.map(({text, link,navTag}, i) => (
                            <Box
                                component='li'
                                key={i}
                                className={currentNav === navTag ? "on" : ""}
                            >
                                <Link to={link}>
                                    {text}
                                </Link>
                            </Box>
                        ))}
                    </Box>
                </Toolbar>
            </AppBar>
        </Box>
    )
}
