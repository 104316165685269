import * as React from 'react'
import {graphql, StaticQuery, Link} from 'gatsby'

import {pathPrefix} from '../../gatsby-config'
import {Collapse, List, ListItemButton, ListItemText} from '@mui/material';
import {ExpandLess, ExpandMore} from '@mui/icons-material';
import {getCurrentPath, getCurrentPathNav} from "../tools/path";


function isLinkItem(item) {
    return Boolean(item.link)
}

function render(item, id, currentPath) {
    const handleClick = (e) => {
        const link=e.target.getElementsByTagName("a")
        if(link.length===1){
            link[0].click()
        }
    }
    const open = true
    if (isLinkItem(item)) {
        return (
            <ListItemButton key={id} onClick={handleClick}>
                <ListItemText className="lev1-text" primary={item.name}/>
            </ListItemButton>
        )
    } else {
        const lev1Matched = item.items.filter(({link}) => {
            return link === currentPath
        }).length > 0

        return (
            <>
                <ListItemButton className={lev1Matched ? "on" : ""} key={id + "1"} onClick={handleClick}>
                    <ListItemText className="lev1-text" primary={item.name}/>
                    {open ? <ExpandLess/> : <ExpandMore/>}
                </ListItemButton>
                <Collapse key={id + "2"} in={open} timeout="auto" unmountOnExit>
                    <List component="ul" className="lev2" disablePadding>
                        {
                            item.items.map(({name, link}, i) => {
                                return <ListItemButton component="li" className={link === currentPath ? "on" : ""} key={i}
                                                       sx={{pl: 4}} onClick={handleClick}>
                                    <Link to={link}>
                                        <ListItemText primary={name}/>
                                    </Link>
                                </ListItemButton>
                            })
                        }
                    </List>
                </Collapse>
            </>
        )

    }
}

export function Sidebar() {
    return (
        <StaticQuery
            query={graphql`
        query MyQuery {
          allSidebarJson {
            edges {
              node {
                id
                name
                link
                items {
                  name
                  link
                }
              }
            }
          }
        }
      `}
            render={(data, pathname) => {
                const rootItems = data.allSidebarJson.edges.map((v) => v.node)
                const currentPath = getCurrentPath(pathname)
                return (
                    <List
                        className="side-bar"
                        sx={{width: 180, bgcolor: 'background.paper'}}
                        component="nav"
                    >
                        {rootItems.map((v) => render(v, v.id, currentPath))}
                    </List>
                )
            }}
        />
    )
}
