import {pathPrefix} from "../../gatsby-config";

export function getCurrentPathNav(pathname) {
    let currentPath =
        typeof window !== 'undefined'
            ? window.location.pathname.replace(pathPrefix, '')
            : '/'
    if (pathname) {
        currentPath = pathname
    }
    if (currentPath.indexOf('/docs/') !== -1) {
        return "/doc"
    }
    return "/"
}

/**
 * 获取地址对应的路由地址-去除语言、结尾斜杠
 * @param pathname
 * @return {string}
 */
export function getCurrentPath(pathname) {
    let currentPath =
        typeof window !== 'undefined'
            ? window.location.pathname.replace(pathPrefix, '')
            : '/'
    if (pathname) {
        currentPath = pathname
    }
    currentPath=currentPath.replace('/en/',"/")
    currentPath=currentPath.replace(/(.+)\/$/,"$1")
    return currentPath
}